// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aboutme-js": () => import("./../../../src/pages/aboutme.js" /* webpackChunkName: "component---src-pages-aboutme-js" */),
  "component---src-pages-almaccount-js": () => import("./../../../src/pages/almaccount.js" /* webpackChunkName: "component---src-pages-almaccount-js" */),
  "component---src-pages-almlogin-js": () => import("./../../../src/pages/almlogin.js" /* webpackChunkName: "component---src-pages-almlogin-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privateroute-js": () => import("./../../../src/pages/privateroute.js" /* webpackChunkName: "component---src-pages-privateroute-js" */),
  "component---src-pages-protected-admin-js": () => import("./../../../src/pages/protected/Admin.js" /* webpackChunkName: "component---src-pages-protected-admin-js" */),
  "component---src-pages-protected-billing-js": () => import("./../../../src/pages/protected/Billing.js" /* webpackChunkName: "component---src-pages-protected-billing-js" */),
  "component---src-pages-protected-home-js": () => import("./../../../src/pages/protected/Home.js" /* webpackChunkName: "component---src-pages-protected-home-js" */),
  "component---src-pages-protected-pull-feed-js": () => import("./../../../src/pages/protected/PullFeed.js" /* webpackChunkName: "component---src-pages-protected-pull-feed-js" */),
  "component---src-pages-protected-settings-js": () => import("./../../../src/pages/protected/Settings.js" /* webpackChunkName: "component---src-pages-protected-settings-js" */),
  "component---src-pages-protected-todoapp-js": () => import("./../../../src/pages/protected/todoapp.js" /* webpackChunkName: "component---src-pages-protected-todoapp-js" */)
}

